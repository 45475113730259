import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Row, SortingOrderType } from '../../defs';
import { BadgerObjectDefinition, Field, FieldType } from '../../../../domain/CompanyDefinition/CompanyDefinition';

const useRows = (objectDefinition: BadgerObjectDefinition | undefined) => {
    const [rows, setRows] = useState<Row[] | undefined>([]);
    const [addedFields, setAddedFields] = useState<string[]>([]);

    useEffect(() => {
        setRows(buildRows(objectDefinition) || []);
    }, [objectDefinition]);

    const buildSortingOrder = (sortingOrder: number | undefined, direction: string) => {
        switch (sortingOrder) {
            case 1:
                return direction === 'ASCENDING' ? SortingOrderType.ASCENDING : SortingOrderType.DESCENDING;
            default:
                return SortingOrderType.UNDEFINED;
        }
    };

    const buildRows = (objectDefinition: BadgerObjectDefinition | undefined): Row[] | undefined =>
        objectDefinition?.fields
            .map((field: Field) => ({
                id: field.id,
                uuid: uuidv4(),
                displayOrder: field.displayOrder,
                label: field.label,
                name: field.name,
                type: field.type,
                isMandatory: field.isMandatory,
                sortingOrder: buildSortingOrder(field.sortingOrder, field.sortingOrderDirection),
                isMain: field.type === FieldType.ADDRESS ? Boolean(field.isMain) : false,
                isSearchable: field.isSearchable,
                isColorizable: field.type === FieldType.PICK_LIST ? Boolean(field.isColorizable) : null,
                isFilterable:
                    field.type === FieldType.PICK_LIST ||
                    field.type === FieldType.NUMBER ||
                    field.type === FieldType.DATE_TIME
                        ? Boolean(field.isFilterable)
                        : null,
                isViewOnly: field.isViewOnly,
                displayType: field.displayType,
                pickList: field.type === FieldType.PICK_LIST ? field.options : null,
                decimals: field.type === FieldType.NUMBER ? field.decimals : null,
            }))
            .sort((a: any, b: any) => a.display_order - b.display_order);

    const resetRows = (): Row[] | undefined => {
        const updatedRows = buildRows(objectDefinition);
        setRows(updatedRows);
        return updatedRows;
    };

    const addRow = (uuid: string, displayOrder: number) => {
        setRows([
            ...(rows ?? []),
            {
                id: null,
                uuid: uuid,
                displayOrder: displayOrder,
                label: '',
                name: '',
                type: '',
                isMandatory: false,
                sortingOrder: SortingOrderType.UNDEFINED,
                isMain: false,
                isSearchable: false,
                isColorizable: null,
                isFilterable: null,
                isViewOnly: false,
                displayType: '',
                pickList: null,
                decimals: null,
            },
        ]);
        setAddedFields([...addedFields, uuid]);
    };

    const deleteRow = (uuid: string) => {
        setRows(rows?.filter((row) => row.uuid !== uuid));
        setAddedFields(addedFields.filter((field) => field !== uuid));
    };

    const updateRow = (values: any, uuid: string) => {
        const updatedRows = [...(rows ?? [])];
        const row = updatedRows.find((row) => row.uuid === uuid);
        if (row) {
            row.isMandatory = values.isMandatory;
            row.isSearchable = values.isSearchable;
            row.isColorizable = values.isColorizable;
            row.isFilterable = values.isFilterable;
            row.isViewOnly = values.isViewOnly;
            row.pickList = values.pickList;
            row.decimals = values.decimals;
            row.isMain = values.isMain;
            row.type = values.type;
        }
        setRows(updatedRows);
    };

    return { rows, updateRows: setRows, updateRow, resetRows, addRow, deleteRow, addedFields };
};

export default useRows;
