import { Outlet } from 'react-router-dom';
import TabsPanel from './TabsPanel/TabsPanel';
import { TabsPanelControllerType } from './TabsPanel/tabsPanelController';
import { CompanyDefinitionContentControllerType } from './companyDefinitionContentController';
import { styled } from 'styled-components';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const CompanyDefinitionContent = ({
    useController,
    useTabsPanelController,
}: {
    useController: CompanyDefinitionContentControllerType;
    useTabsPanelController: TabsPanelControllerType;
}) => {
    const { tabs } = useController();
    return (
        <Container>
            <TabsPanel tabs={tabs} useController={useTabsPanelController} />
            <Outlet />
        </Container>
    );
};

export default CompanyDefinitionContent;
