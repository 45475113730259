import { Controller } from 'react-hook-form';
import { Control, RegisterOptions } from 'react-hook-form/dist/types';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import { PickListOption } from '../../../../domain/CompanyDefinition/CompanyDefinition';

const StyledTextField = styled(TextField)`
    & .MuiInputBase-root {
        height: 40px;
        margin: 8px 0;
    }
`;

export interface Props {
    disabled?: boolean;
    rules: RegisterOptions;
    control: Control;
    name: string;
    onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

const PicklistValuesTypeField = ({ disabled, rules, control, name, onClick }: Props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
                if (field.value === undefined || field.value === null) {
                    return <></>;
                }

                const value = field.value.map((option: PickListOption) => `${option.label}:${option.value}`).join(', ');
                return (
                    <StyledTextField
                        value={value}
                        InputProps={{ readOnly: true }}
                        onClick={onClick}
                        disabled={disabled}
                        error={fieldState.invalid}
                    />
                );
            }}
        />
    );
};

export default PicklistValuesTypeField;
