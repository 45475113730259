import { useEffect, useMemo, useState } from 'react';
import { CONDITIONAL_COLUMNS, buildColumns } from '../columns';

const useGetVisibleColumns = (getConditionalColumns: boolean) => {
    const columns: any[] = useMemo(buildColumns, []);
    const [visibleColumns, setVisibleColumns] = useState<any[]>([]);

    useEffect(() => {
        const conditionalColumns = columns.filter((column) => CONDITIONAL_COLUMNS.includes(column.name));
        conditionalColumns.forEach((column) => (column.visible = getConditionalColumns));
        setVisibleColumns(columns.filter((column) => column.visible));
    }, [columns, getConditionalColumns]);

    return { columns: visibleColumns };
};

export default useGetVisibleColumns;
