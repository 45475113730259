import Checkbox from '@mui/material/Checkbox';
import { Controller } from 'react-hook-form';

export interface props {
    control: any;
    name: string;
    rules: any;
    disabled: boolean;
    handleChange?: (value: boolean) => void;
}

const BooleanField = ({ control, name, rules, disabled, handleChange }: props) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) =>
                field.value !== undefined ? (
                    <Checkbox
                        checked={Boolean(field.value)}
                        disabled={disabled}
                        onChange={(data) => {
                            if (handleChange) handleChange(data.target.checked);
                            field.onChange(data);
                        }}
                    />
                ) : (
                    <></>
                )
            }
        />
    );
};

export default BooleanField;
